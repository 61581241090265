import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "../Stringify";
import MyAutocomplete from "../Main/MyAutocomplete";

export const objectify = (items, field = '_id') => {
    return items.reduce((acc, it) => {
        return {...acc, [it[field]]: it}
    }, {})
}

export function getHashCategories(cb) {
    Promise.all([
        global.http.get('/hash-category', {per_page: 10000, filter: {status: {$ne: 'unactive'}}}),
        global.http.get('/hash-tag', {per_page: 10000, filter: {status: {$ne: 'unactive'}}}),
    ]).then(r => {
        cb && cb({
            categories: r[0]?.items,
            hashTags: r[1]?.items,
            grouppedHashTags: _.groupBy(r[1]?.items, 'hashCategory')
        })
    })
}

function Layout2(props) {
    props = props?.props || props || {}
    let {item, onChange} = props;
    let childSize = props?.field?.childSize || props?.childSize || 3;
    item.info ??= {}
    item.info.tags ??= {}
    let tags = item.info.tags;

    console.log('*........ ## ROOT RENDER MAIN GROUPS', props);


    let [categories, setCategories] = useState([])
    let [hashTags, setHashTags] = useState([])
    let [grouppedHashTags, setGrouppedHashTags] = useState({})
    useEffect(() => {
        getHashCategories(({categories, hashTags, grouppedHashTags}) => {
            setCategories(categories)
            setGrouppedHashTags(grouppedHashTags)
            setHashTags(hashTags)
        })
    }, [])
    return <div className={'row'}>
        {(categories || []).map((it, ind) => {
            let catId = it?._id;
            return (<div key={ind} className={`col-xs-${childSize}`}>
                {it.name}
                <MyAutocomplete
                    isMulti={true}
                    items={grouppedHashTags[catId]} value={tags[catId]} onChange={(v) => {
                    item.info.tags[catId] = v;
                    console.log("qqqqq itemtttttttttttttt", item.info.tags);
                    onChange && onChange(item.info, 'info')
                }}></MyAutocomplete>
            </div>)
        })}


        {/*Main Groups*/}
        {/*<Stringify item={categories}></Stringify>*/}
        {/*<Stringify item={grouppedHashTags}></Stringify>*/}
    </div>
}

export default Layout2
