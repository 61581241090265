import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import GroupButton from "../../libs/GroupButton/GroupButton";
import {deleteTask, getTaskDetails, getTaskDetailsSync, saveTaskDetails} from "./TaskSelector";
import MyModal from "../../libs/MyModal";
import ZadachiEdit from "../Main/ZadachiEdit";
import {getter} from "../methods/Fetcher";
import Stringify from "../Stringify";
import Input from "../../libs/Input";

let taskHttp = getter('/task')

function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);

    let [task, setTask] = useState({})
    let [editTask, setEditTask] = useState(null)
    let {item, taskId} = props;
    let {withStatus} = props;
    let statuses = ['', 'ping', 'test', 'done', 'archive']


    useEffect(() => {
        getTaskDetails(taskId, setTask)
        // getTaskDetails(taskId, setEditTask)
    }, [taskId, window?.sprintComponentCd])

    task = getTaskDetailsSync(taskId)
    console.log("qqqqq RRRR render", taskId, task?.name);

    return <div onClick={(e) => {
        e.preventDefault();
        e.stopPropagation()
        !editTask && setEditTask(task)
        return null;
    }}>

        <MyModal
            size={'lg'}
            isOpen={editTask}
            onClose={() => setEditTask(null)}
        >
            <ZadachiEdit item={editTask} onChange={(v) => {
                setEditTask(v)
            }} onClose={(v) => {
                if (!v) {
                    props.onDeleteTask && props.onDeleteTask(editTask)
                    return deleteTask(editTask)
                }
                console.log("qqqqq vvvvvvvvvvvvvvvvv",v );
                saveTaskDetails(v, setEditTask, true)
                taskHttp.smartSave(v, (r) => {
                })
            }}></ZadachiEdit>
        </MyModal>
        <div title={task?._id}>{task?.name || '---'}</div>
        {!!withStatus && <>
            <div></div>
            <GroupButton
                items={statuses}
                value={item?.status}
            ></GroupButton>
        </>}
    </div>
}

export default Layout2
