import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import './daysSelector.css'
import Button from "../../libs/Button";

function getWeeksGroupedByMonth(startDelta) {
    const now = new Date();
    const getMonthWeeks = (year, month) => {
        const weeks = [];
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0);

        // Adjust the start of the first week to Monday
        const firstDayOfWeek = new Date(firstDayOfMonth);
        // If the first day is Sunday (0), set to the previous Monday (-6); otherwise, set to the previous Monday (1 - day)
        let fd = firstDayOfWeek.getDay()
        firstDayOfWeek.setDate(firstDayOfWeek.getDate() - (fd < 2 ? 6 - fd : fd - 1));

        // Adjust the end of the last week to Sunday
        const lastDayOfWeek = new Date(lastDayOfMonth);
        let ld = lastDayOfWeek.getDay()
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + (ld < 2 ? ld - 1 : 7 - ld));

        // Iterate from the first day of the first week to the last day of the last week
        let currentDay = new Date(firstDayOfWeek);
        while (currentDay <= lastDayOfWeek) {
            const weekStart = new Date(currentDay);
            const daysInWeek = [];

            // Collect all days in the week
            for (let i = 0; i <= 6; i++) {
                const weekDay = new Date(new Date(weekStart).toODB());
                weekDay.setDate(weekDay.getDate() + i);
                daysInWeek.push({
                    odb: weekDay.toISOString().slice(0, 10),
                    month: weekDay.getMonth(),
                    date: weekDay.getDate()
                });
            }

            currentDay.setDate(currentDay.getDate() + 6);
            const weekEnd = new Date(currentDay);
            weeks.push({
                start: weekStart.toISOString().slice(0, 10),
                end: weekEnd.toISOString().slice(0, 10),
                days: daysInWeek,
                month: firstDayOfMonth.getMonth(),
            });
            currentDay.setDate(currentDay.getDate() + 1);
        }

        return {weeks, year: firstDayOfMonth.getFullYear(), month: firstDayOfMonth.getMonth()};
    };

    const year = now.getFullYear();
    const month = now.getMonth();

    return [
        getMonthWeeks(year, startDelta + month - 1),
        getMonthWeeks(year, startDelta + month),
        getMonthWeeks(year, startDelta + month + 1)
    ];
}

export const pub = (v) => {
    return v < 10 ? `0${v}` : v;
}

function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);

    let m = global.m;
    let {filter, setFilter, odbs} = props || {}
    filter.start = +filter?.start || 0;
    odbs = odbs || {}


    let months = getWeeksGroupedByMonth(filter?.start || 0)
    console.log("qqqqq months", months);
    return <>
        <div className="ib">
            <Button color={4} onClick={(scb) => {
                scb && scb()
                setFilter({start: filter?.start - 1})
            }}>-
            </Button>
            <Button color={4} onClick={(scb) => {
                scb && scb()
                setFilter({start: filter?.start + 1})
            }}>+
            </Button>
        </div>
        <div className="row">
            <div className="col-xs-12">


            </div>
            {(months || []).map((it0, ind) => {
                console.log("qqqqq ittttttttttttttttt", it0);
                return (<div key={ind} className="col-xs-4">

                    <div>
                        {pub(it0.month)}-{it0.year}
                    </div>
                    {(it0.weeks || []).map((it, ind) => {
                        if ((it.days.at(-1).month != it0?.month) && (it.days.at(0).month != it0?.month)) {
                            return null;
                        }
                        return (<div key={ind}>
                            {(it.days || []).map((it2, ind) => {
                                let isSame = it2.month == it.month;
                                let odbStat = odbs[it2.odb]
                                return (<div
                                    onClick={() => {
                                        props.onClick && props.onClick(it2.odb)
                                    }}
                                    title={it2.odb}
                                    key={ind} className={'ib dayItem ' +
                                    (!isSame ? ' another ' : ' same ') +
                                    (it2.odb == filter?.odb ? ' currentDate' : '')
                                }>
                                    {it2.date}
                                    {/*<div></div>*/}
                                    {/*{odbStat?.total?.hours}*/}
                                    {/*<div></div>*/}
                                    {/*{odbStat?.total?.planHours}*/}
                                    {/*{it2.month} {it0.month}*/}
                                    <div className={"lineDay " + (!odbStat?.total?.planHours ? 'o2' : '')}
                                         style={{width: Math.min(100, ((odbStat?.total?.planHours || 0) / 0.08)) + '%'}}></div>
                                    <div className={"lineDay line2 " + (!odbStat?.total?.hours ? 'o2' : '')}
                                         style={{width: Math.min(100, ((odbStat?.total?.hours || 0) / 0.08)) + '%'}}></div>
                                </div>)
                            })}


                        </div>)
                    })}


                    {/*{(it || []).map((it, ind) => {*/}
                    {/*    return (<div key={ind} className={'ib dayItem'}>*/}
                    {/*        {it}*/}
                    {/*        <div>*/}
                    {/*            <div className="lineDay"></div>*/}
                    {/*            <div className="lineDay line2"></div>*/}
                    {/*        </div>*/}
                    {/*    </div>)*/}
                    {/*})}*/}

                </div>)
            })}


        </div>
    </>
}

export default Layout2
