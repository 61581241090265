
export const STATUS_ITEMS = [
    {name: 'Авто', value: 'auto'},
    {name: 'Бэклог', value: 'backlog'},
    {name: 'Спринт', value: 'sprint'},
    {name: 'Процесс', value: 'process'},
    {name: 'Пинг', value: 'ping'},
    {name: 'Ревью', value: 'review'},
    {name: 'Сделано', value: 'done'},
    {name: 'Архив', value: 'archive'},
]

export const THANKS_ITEMS = [
    {name: '-', value: '-'},
    {name: '1', value: '1'},
    {name: '2', value: '2'},
    {name: '3', value: '3'},
]
