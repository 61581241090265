import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "../Stringify";
import Smart from "../../libs/Smart";
import Button from "../../libs/Button";
import Input from "../../libs/Input";
import TaskSelector from "./TaskSelector";


function Layout2(props) {
    let {onChange} = props;
    let [item, setItem] = useState({})

    useEffect(() => {
        setItem(JSON.parse(JSON.stringify(props.item)))
    }, [JSON.stringify(props.item)])

    function onUpdate() {
        if (isNew) {
            global.http.post('/sprint', item).then(r => {
                console.log("qqqqq aaaaaaaaaaaaaaaaaaaaa Create", r);
                onChange(r)
                onChange(null)
            })
        } else {
            global.http.put('/sprint', item).then(r => {
                console.log("qqqqq aaaaaaaaaaaaaaaaaaaaa Update", r);
                onChange(r)
                onChange(null)
            })
        }
    }

    let isNew = !item?._id;
    let isType = +item?.type;

    if (isType) {
        let day = 24 * 3600 * 1000;

        let cd = new Date(item.start);
        let mo = cd.getMonth();
        let year = cd.getFullYear();

        let isSet = false;
        if (item?.type == 7) {
            item.start = new Date(item.start).getTime() - new Date(item.start).getDay() * day
            item.end = new Date(item.start).getTime() + 7 * day;
            item.endOdb = new Date(item.end).toODB();
        } else {
            let delta = item?.type == '30' ? 1 : item?.type == 90 ? 3 : 6;


            item.endOdb = new Date(year, mo + delta, 1).toODB();
            item.startOdb = new Date(year, mo, 2).toODB();

            item.start = new Date(item.startOdb)
            item.end = new Date(item.endOdb)

        }

    }
    return <div>

        Страница EditSprint

        <Smart
            obj={item}
            onChange={(v) => {
                console.log("qqqqq vvvvvvvvvvvvvvvvv", v);
                setItem({...v})
            }}
            items={[
                {name: 'Name', key: 'name'},
                {name: 'Start', key: 'start', type: 'date'},
                {
                    isVisible: (item) => {
                        console.log("qqqqq itemmmmmmmm", item);
                        return !isType;

                    }, name: 'End', key: 'end', type: 'date'
                }, {
                    isVisible: (item) => {
                        console.log("qqqqq itemmmmmmmm", item);
                        return isType;
                    }, Component: ({item}) => {
                        return <>
                            {item.startOdb}
                            <div></div>
                            {item.endOdb}
                        </>
                    }
                },
                {
                    name: 'Type', key: 'type', type: 'group', items: [
                        {name: '7', value: '7'},
                        {name: '30', value: '30'},
                        {name: '90', value: '90'},
                        {name: '180', value: '180'},
                        {name: 'custom', value: 'custom'},
                    ]
                },
                {
                    size: 12, key: 'planTasks',
                    sortable: true,
                    each: [
                        {key: 'name', name: 'Name'},
                        // {
                        //     Component: (v) => {
                        //         console.log("qqqqq vvvvvv444444", v?.localItem);
                        //         return <>
                        //             {v?.localItem?.task || ''}
                        //             <Stringify item={v?.localItem} />
                        //         </>
                        //     }
                        // },
                        {
                            Component: (prop) => {
                                console.log("qqqqq vvvvvv", prop);
                                let {ind, localItem, item, onChange} = prop

                                return <>
                                    <TaskSelector task={localItem?.task} onChange={(task) => {
                                        console.log("qqqqq taskkkkkkkkkkkkkkkk", prop, task);
                                        localItem.task = task;
                                        item.planTasks[ind] = {...localItem}
                                        item.planTasks = [...item.planTasks]
                                        console.log("qqqqq item.plan", prop, item.planTasks);
                                        onChange({}, item.planTasks, 'planTasks')
                                    }}></TaskSelector>

                                </>
                            }
                        }]
                }
            ]}
        ></Smart>
        <Button onClick={(scb) => {
            scb && scb()
            onUpdate()
        }}>{isNew ? 'Создать' : 'Обновить'}</Button>
        <Button
            color={4}
            onClick={(scb) => {
                scb && scb()
                onChange(null)
            }}>Закрыть</Button>
        <hr/>
        {/*<Stringify item={item}></Stringify>*/}

    </div>
}

export default Layout2
