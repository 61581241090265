import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import MyAutoComplete from "../Main/MyAutocomplete";

import "./Notification.css"
import Textarea from "../../libs/Textarea";
import Input from "../../libs/Input";
import Smart from "../../libs/Smart";
import TaskItem from "../Sprint/TaskItem";

function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);

    let items = [{}, {}, {}]
    return <div>
        <div className="row">
            <div className="col-xs-4">
                <a>Все нотификации (15)</a>
                <hr/>
                {(items || []).map((it, ind) => {
                    return (<div key={ind}>
                        #{ind + 1} {it?.name || ''}
                    </div>)
                })}
            </div>
            <div className="col-xs-8">
                <Input placeholder={'Name'}></Input>
                <Textarea placeholder={'Desc'}></Textarea>
                <Input placeholder={'NotificatorAction'}></Input>
                <Input placeholder={'NotificatorGroup'}></Input>
                <hr/>
                <Smart
                    obj={{items: [{}, {}]}}
                    onChange={(v) => {
                        console.log("qqqqq vvvvvv", v);
                    }}
                    items={[{
                        key: 'items',
                        size: 12,
                        sortable: true,
                        // woAdd: true,
                        each: [{
                            size: 12,
                            Component: ({item}) => {
                                return <div className={'row'}>
                                    <div className="ib myauto col-xs-4">
                                        <MyAutoComplete label={'Тип'}></MyAutoComplete>
                                    </div>
                                    <div className="ib myauto col-xs-4">
                                        <MyAutoComplete label={'Компоратор'}></MyAutoComplete>
                                    </div>
                                    <div className="ib myauto col-xs-4">
                                        <MyAutoComplete label={'Значение'}></MyAutoComplete>
                                    </div>
                                    {/*<div className="col-xs-12"></div>*/}
                                    {/*<div className="ib myauto col-xs-6">*/}
                                    {/*    <MyAutoComplete label={'Статус'}></MyAutoComplete>*/}
                                    {/*</div>*/}
                                    {/*<div className="ib myauto col-xs-2">*/}
                                    {/*    <MyAutoComplete label={'Время'}></MyAutoComplete>*/}
                                    {/*</div>*/}
                                    {/*<div className="ib myauto col-xs-2">*/}
                                    {/*    <MyAutoComplete label={'Компоратор'}></MyAutoComplete>*/}
                                    {/*</div>*/}
                                    {/*<div className="ib myauto col-xs-2">*/}
                                    {/*    <MyAutoComplete label={'Значание'}></MyAutoComplete>*/}
                                    {/*</div>*/}
                                </div>
                            }
                        }]
                    }
                    ]}
                />
            </div>
        </div>
    </div>
}

export default Layout2
