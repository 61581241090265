import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import MyAutoComplete from "../Main/MyAutocomplete";

let tasksList;
let tasksObj;
global.http.get('/task', {per_page: 10000}).then((r) => {
    tasksList = r.items;
    tasksObj = r.items?.reduce((acc, it) => {
        return {...acc, [it._id]: it}
    }, {})
})
export const getTaskName = (task) => {
    return (tasksObj[task] || {}).name
}
export const getTasks = (cb) => {
    if (!tasksList) {
        setTimeout(() => {
            getTasks(cb)
        }, 100)
    } else {
        cb && cb(tasksList)
    }
}
export const getTaskDetails = (taskId, cb) => {
    if (!tasksObj) {
        setTimeout(() => {
            getTaskDetails(taskId, cb)
        }, 100)
    } else {
        cb && cb(tasksObj[taskId])
    }
    return tasksObj ? tasksObj[taskId] : null;
}
export const getTaskDetailsSync = (taskId, cb) => {

    return tasksObj ? tasksObj[taskId] : null;
}

export const saveTaskDetails = (task, closeCb, isForceReload) => {
    let isFound = false;
    _.each(tasksList, (item, ind) => {
        if (item._id == task._id) {
            isFound = true;
            tasksList[ind] = {...task}
        }
    })

    if (!isFound) {
        tasksList.unshift(task)
    }
    tasksObj[task._id] = task;

    closeCb && closeCb()
    isForceReload && window.sprintComponentRerender && window.sprintComponentRerender();
}
export const deleteTask = (task) => {
    tasksList = tasksList.filter(it => {
        return it?._id != task?._id
    })
    tasksObj[task._id] = null;
}
export const getTasksSync = () => {
    return tasksList || []
}

function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);

    let [tasks, setTasks] = useState([])
    let {task, onChange} = props;

    useEffect(() => {
        getTasks(setTasks)
    }, [])
    return <div>
        Tasks Selector: {getTaskName(task)}
        <MyAutoComplete items={tasks} value={tasksObj[task]} onChange={v => {
            console.log("qqqqq task seelct", v);
            onChange(v, tasksObj[v])
        }}></MyAutoComplete>
    </div>
}

export default Layout2
