import React, {useState, useEffect} from 'react';
import {getStats2, pubAccount, pubTeam, pubType} from "./methods";
import Stringify from "../Stringify";
import _ from 'underscore';
import {toRub, toRubByCurrency, toRubPub} from "../methods/GenerateHistory";
import Input from "../../libs/Input";
import MyModal from "../../libs/MyModal";
import Select from "../../libs/Select";
import {toGroup} from "./GroupRender";
import TransactionPreview from "./TransactionPreview";
import {keysMapFn} from "../methods/keysFnForTable";
import Button from "../../libs/Button";

export function round(v, k = 2) {
    return +((+v || 0).toFixed(k))
}

export function toUniqueArr(arr, field) {
    let keysObj = {};
    _.each(arr, (item, ind) => {

        if (!item) {
            return;
        }
        let key = item[field];

        if (key) {
            keysObj[key] = true
        }
    })

    return Object.keys(keysObj)

}

export function toKeys(obj) {
    return Object.keys(obj).filter(it => it)
}

function TopStats(props) {
    let {transactions, transactionsY, filter, teams, period, onChangeFilter} = props


    let [filteredItems, setFilteredItems] = useState(null)
    let [monthlyType, _setType] = useState(filter.monthlyType || '')
    // let [isBig, setIsBig] = useState(filter.isBig || '')
    let [stats2, setStats2] = useState({})
    let [stats3, setStats3] = useState({})
    let [stats4, setStats4] = useState({})

    let setMonthlyType = (monthlyType) => {
        _setType(monthlyType)
        onChangeFilter({monthlyType})
    }
    let getDDS = (r) => {
        let obj = {}
        let accountsObj = {}, teamsObj = {}
        _.each(r.items, (item, ind) => {
            let {type, account, period, team, total, currency, amount} = item;

            accountsObj[account] = true;
            teamsObj[team] = true;

            _.each(['total', account], (__account, ind) => {
                let curAccount = __account == 'total' ? account : __account;
                _.each(['total', team], (team, ind) => {
                    obj[__account] ??= {}
                    obj[__account][team] ??= {amount: 0}
                    obj[__account][team].amount += toRub(amount, account, period)
                    obj[__account][team].items ??= []
                    obj[__account][team].items.push(item)
                })
            })
        })
        return {accounts: toKeys(accountsObj), teams: toKeys(teamsObj), details: obj};
    }
    useEffect(() => {
        // if (!transactions || !transactions.length) {
        //     return;
        // }

        let filter = {period: {$lte: period}}
        if (teams && teams.length) {
            filter.teams = {$in: teams}
        }
        let removeFields = (obj, fields) => {
            _.each(fields, (item, ind) => {
                delete obj[item]

            })
            return obj;
        }
        // filter.status = 'paid'

        Promise.all([
            global.http.get('/total-balances', {filter, fields: ['_ids'].join('|')}),
            // global.http.get('/get-yearly-transactions', {period}),

            // global.http.get('/total-balances', {
            //     filter: removeFields({...filter}, ['period']), isMonthly: true,
            //     period,
            //     fields: ['type', '_ids'].join('|')
            // }),
            // global.http.get('/total-balances', {filter: {...filter, period: period}, onMonthOnly: true, fields: ['_ids'].join('|')}),

        ]).then(vv => {
            let [r, r1, r2] = vv;
            console.log("qqqqq rrrrrrrrrrr Monthly Balances", r1);
            let stats2 = getDDS(r)
            setStats2(stats2)
        })

    }, [transactions, period, teams])


    let statsY = getStats2(transactionsY, keysMapFn({monthlyType}), {
        amountKey: 'amountMonthly', filterFinalItem: (item) => {
            console.log("qqqqq item filter fienal item", item, period);
            return (item.periodFrom <= period) && (item.periodTo >= period)
        }
    });
    let stats = getStats2(transactions, keysMapFn({monthlyType}), {amountKey: 'amount'});

    let teamsRes = getActiveTeams(stats.teams);//.filter(it => window?.info?.teamsObj[it._id || it]);
    let nalog_fields = ['oklad_nalog', 'premia_nalog', 'year_premia_nalog'];
    let total_fot_fields = ['oklad', 'premia', 'year_premia', ...nalog_fields]

    window.tr = transactions;
    let obj = {}
    let teamsDDS = {}
    let accountsDDS = {}


    _.each(transactions, (tr, ind) => {
        _.each(tr.items, (item, ind) => {
            accountsDDS[item.account] = true
            teamsDDS[item.team] = true
            _.each([item.account, 'total'], (acc, ind) => {
                let team = item.team;
                _.each([item.team, 'total'], (team, ind) => {
                    obj[acc] ??= {}
                    obj[acc][team] ??= {amount: 0, itemIds: {}}
                    obj[acc][team].amount += toRub(item.amount, item.account, item.period) || 0;
                    obj[acc][team].itemIds[tr._id] = true;
                })
            })
        })
    })

    let statsDDS = {details: obj, accounts: Object.keys(accountsDDS), teams: Object.keys(teamsDDS)}
    console.log("qqqqq statsDDS", statsDDS);
    window.totalStats = stats;
    let sumKeys = (st, arr) => {
        let amount = 0;
        if (arr == 'all') {
            console.log("qqqqq sum KEYS", arr, st, amount);

        }
        arr = arr == 'all' ? Object.keys(st).filter(it => {
            return !{debitor: true, total: true}[it] && !/calc_only\./gi.test(it)
        }) : arr;

        _.each(arr, (field, ind) => {
            amount += getV(st, field)
        })

        return amount;

    }

    let getV = (st, field) => {
        return (st[field] || {}).total || 0
    }

    let openDetails = (keys, st, stY) => {
        let _ids = {}
        let _idsY = {}
        stY ??= {}
        st ??= {}
        _.each(keys, (key, ind) => {
            _ids = {..._ids, ...(st[key] || {}).details || {}}
            _idsY = {..._idsY, ...(stY[key] || {}).details || {}}
        })
        let filtered = (transactions || []).filter(it => {
            let {_id} = it;
            if (!_ids[_id] && !_idsY[_id]) {
                return false;
            }

            it.isYear = it.isYear || _idsY[_id] && !_ids[_id]
            return true;
        })
        let filteredY = (transactionsY || []).filter(it => {
            let {_id} = it;
            if (!_idsY[_id]) {
                return false;
            }
            it.isYear = true
            return true;
        })
        // let group = toGroup(filtered, ['teams', ])
        setFilteredItems([...filtered, ...filteredY])
        console.log("qqqqq _ids", _ids, _idsY);

    }
    let openDetailsFromDD = (_ids) => {
        setFilteredItems((transactions || []).filter(it => {
            return _ids[it._id]
        }))
    }

    let {isBig} = filter;
    console.log("qqqqq stats2stats2stats2", stats2, statsDDS);

    let bigItems = isBig ? [{name: 'Распределения', key: 'raspredi'},

        {name: 'Дебиторка', key: "calc_only.debitor", k: -1},
        // {name: 'Корп налог', key: "calc_only.prihod_nalog"},

        {name: 'Траты', key: 'calc_only.trati'},
        {name: 'Переводы', key: 'calc_only.perevod'},
        {name: 'Траты (M)', key: 'calc_only.mtrati'},
        {name: 'Траты (Y)', key: 'calc_only.ytrati'},
        {
            name: 'ФОТ', sumKeys: total_fot_fields
        },
        // 'year_premia_nalog', 'year_premia'
        {name: 'Оклад', key: 'oklad'},
        {name: 'Премии', key: 'premia'},
        {name: 'Премии Г', key: 'year_premia'},
        {name: 'Офис налог', key: 'nalog_office'},
        {
            name: 'Налог', sumKeys: nalog_fields
        },
        {name: 'Офис плюс', key: 'cacl_only.office_plus'},
        {
            name: 'Налог %',
            isPerc: true, fn(st) {
                // return [(getVS(st, nalog_fields) || 1), (getVS(st, total_fot_fields) || 1)].join("__")
                let v1 = sumKeys(st, nalog_fields) || 0;

                return Math.round(100 * (v1 || 0) / ((sumKeys(st, total_fot_fields) || 1) - v1))

            }
        },] : []
    return <div className={'row'}>

        <MyModal
            size={'full'}
            isOpen={filteredItems}
            onClose={() => setFilteredItems(false)}
        >
            <FilteredTransactions items={filteredItems}></FilteredTransactions>
        </MyModal>
        <div className="col-xs-6">
            Балансы на этот и пред месяца {period}
            <div className="overflowAuto">
                <DDS stats={stats2} isBig={isBig}></DDS>
            </div>
            ДДС только за текущий период {period}
            <div className="overflowAuto">
                <DDS isBig={isBig} stats={statsDDS} openDetailsFromDD={openDetailsFromDD}></DDS>
            </div>
        </div>
        <div className="col-xs-6 ">
            Распеределение за этот период
            <div className="ib">
                <Select items={['month', 'onlyy', 'withy']} type={'checkbox'} value={monthlyType}
                        onChange={setMonthlyType}></Select>
            </div>

            <div className="overflowAuto">
                <table className="table table-striped table-hover table-bordered tbodyStats table-resposive ">
                    <thead className={''}>
                    <tr>
                        <th>Name</th>
                        <th>Тотал</th>
                        {(teamsRes || []).map((team, ind) => {
                            return (<th key={ind}>
                                {pubTeam(team)}
                            </th>)
                        })}
                    </tr>
                    </thead>
                    {([
                        {name: 'Оборот c Корп', key: 'calc_only.prihod_and_korp', bold: true},
                        {name: 'Оборот - Корп', key: 'calc_only.prihod_wo_korp', bold: true},
                        {
                            name: 'Прибыль', key: 'calc_only.total_wo_raspredi', bold: true
                        },
                        {
                            bold: true,
                            name: 'Прибыль c распр', key: 'calc_only.total', fn(st) {
                                let v1 = getV(st, 'calc_only.total')
                                let v3 = getV(st, 'cacl_only.office_plus')
                                return Math.round((v1 + v3))

                            }
                        },
                        {
                            name: 'Рентаб %', postfix: '%',
                            bold: true,
                            isPerc: true,
                            openKeys: ['calc_only.total'], fn(st) {
                                let v1 = getV(st, 'calc_only.total_wo_raspredi');
                                let v2 = getV(st, 'calc_only.prihod_and_korp')
                                // let allPribil = sumKeys(st, 'all') || 1;
                                // let raspredi = sumKeys(st, ['raspredi']) || 1;
                                // let allOborot = Math.abs(getV(st, 'prihod') || 0)
                                // console.log("qqqqq allPribil", allPribil, allOborot);
                                return (Math.round((1) * 100 * (v1) / (v2 || 1)))

                            }
                        }, {
                            name: 'Рентаб % с распр', postfix: '%',
                            bold: true,
                            isPerc: true,
                            openKeys: ['calc_only.total'], fn(st) {
                                let v1 = getV(st, 'calc_only.total')
                                let v3 = getV(st, 'cacl_only.office_plus')
                                let v2 = getV(st, 'calc_only.prihod_and_korp');
                                let perc = Math.round((1) * 100 * (v1 + v3) / (v2 || 1))
                                return (perc)
                            }
                        },

                        ...bigItems || []
                        // {name: 'Налог офис', key: 'nalog_office'},
                    ] || []).map((item, ind) => {
                        let type = item.key;
                        let k = item.k || 1;
                        return (<tbody key={ind} className={''} data-type-key={type}
                                       style={{fontWeight: item?.bold ? 'bold' : '400'}}>
                        <tr>
                            <td onClick={() => {
                                setFilteredItems([])
                            }}>
                                {item.name}
                                {/*{key}*/}
                            </td>
                            {/*<td>{statsKey?.total?.total}</td>*/}
                            {(['total', ...teamsRes || []]).map((team, ind) => {
                                let st = stats[team] || {}
                                let stY = statsY[team] || {}

                                let gv = (st, _item) => {
                                    _item = _item || item;

                                    st = st || {}
                                    let value;
                                    if (_item.sumKeys) {
                                        value = sumKeys(st, _item.sumKeys)
                                    } else {
                                        value = _item.fn ? _item.fn(st) : (((st || {})[type] || {}).total || 0)
                                    }
                                    return value;
                                }

                                let value = gv(st)
                                let value3 = gv(stY)

                                let totalValue = monthlyType == 'onlyy' ? value3 : monthlyType == 'withy' ? value + value3 : value;
                                // let value3 = gv(st3)
                                return (<td key={ind} onClick={() => {
                                    openDetails(item.key ? [item.key] : item.sumKeys || item.openKeys || [], st, stY)
                                }}>
                                    <ToRub isPerc={item?.isPerc}>{k * totalValue}</ToRub>
                                    {/*{monthly && <>({round(k * value3, 0)})</>}*/}
                                    {/*| {value3}*/}
                                    {/*| {round(k * (value3), 0)}{item.postfix || ''} {type} {team}*/}
                                </td>)
                            })}
                        </tr>
                        </tbody>)
                    })}
                </table>
            </div>
            {/*<Stringify item={statsY}></Stringify>*/}
            {/*<Stringify item={stats}></Stringify>*/}
        </div>

    </div>

}


export function FilteredTransactions(props) {
    let filteredItems = props.items;
    return <>
        Детали транзакций
        <div className="col-xs-12">

            {(filteredItems || []).map((it, ind) => {
                let amountPositive = 0;
                let amountNegative = 0;
                _.each(it.items, (item, ind) => {
                    if (item.amount > 0) {
                        amountPositive += item.amount
                    } else {
                        amountNegative += item.amount;
                    }

                })

                return (<div key={ind} className={'row'} onClick={() => {
                    //window.openMainModal({type: 'transaction', item: it})
                }}>
                    <TransactionPreview item={it}></TransactionPreview>
                    {/*{it.isYear && <span className={'label label-info'}>Годовая</span>} {it._id} {pubType(it.type)}*/}

                    {/*{amountPositive && <div className={'ib'}>+<ToRub>{amountPositive}</ToRub></div>}*/}
                    {/*{amountNegative && <div className={'ib'}>-<ToRub>{amountNegative}</ToRub></div>}*/}
                </div>)
            })}
        </div>
    </>
}

export function getActiveTeams(teams) {
    let v = (teams || []).filter(it => {
        return window?.info?.teamsObj[it?._id || it]
    })

    console.log("qqqqq active teams", teams, v);
    return v;

}


function DDS(props) {

    let stats2 = props.stats;
    let teams = getActiveTeams(stats2.teams)
    let bigItems = props.isBig ? stats2.accounts || [] || [] : []
    return <table className="table table-striped table-hover table-bordered tbodyStats">
        <thead className={''}>
        <tr>
            <th>Name</th>
            <th>Тотал</th>
            {(teams || []).map((team, ind) => {
                return (<th key={ind}>
                    {pubTeam(team)}
                </th>)
            })}
        </tr>
        </thead>
        {([{name: 'Итого', key: 'total'}, ...bigItems]).map((it, ind) => {
            let account = it?.key || it;

            return (<tbody key={ind}>
            <tr onClick={() => {
                // props.openDetails && props.openDetails()
            }}>
                <td>
                    {it.name && <strong>{it.name}</strong>}
                    {!it.name && <span>{pubAccount(it)}</span>}
                </td>
                {(['total', ...teams || []]).map((team, ind) => {
                    let am = ((stats2.details || {})[account] || {})[team]

                    return (<td key={ind} data-account-key={account} onClick={() => {
                        console.log("qqqqq show details Account TEam", {
                            account,
                            team
                        }, am);
                        props.openDetailsFromDD && props.openDetailsFromDD(am.itemIds || {})
                    }}>
                        <ToRub>{am?.amount || 0}</ToRub>
                    </td>)
                })}
            </tr>
            </tbody>)
        })}
    </table>
}

let pubPerc = (v) => {
    if (v > 1000) {
        return '999+'
    } else if (v < -1000) {
        return '-999'
    }
    return v;
}

export function ToRub(props) {
    let {isPerc, k = 1, prefix, postfix, woPull, account, period} = props || {};
    // toRub(amount, account, period)
    // let isPerc = props?.postfix;

    let value = props.children
    value = +value * k;
    let valueRub = toRub(value, account, period);

    let plusSign = +value > 0 ? '+' : ''
    postfix = postfix == 'rub' || postfix == 'rur' ? ' ₽' : postfix
    return <span
        data-sign={plusSign === '+' ? 'plus' : 'minus'}
        className={'rubDetails ' + (woPull ? 'woPull' : '')}>
        {prefix || ''}{isPerc ? pubPerc(Math.round(value) || 0) : (plusSign + toRubPub(valueRub))}{isPerc ? '%' : ''}{postfix || ''}</span>
}

export default TopStats
