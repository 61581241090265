import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import {Line, Pie} from "react-chartjs-2";
import 'chart.js/auto';
import {getTasks} from "./TaskSelector";
import Stringify from "../Stringify";
import {objectify} from "./MainGroups";
import MyModal from "../../libs/MyModal";


export function calcSum(start, end) {
    let day = 1000 * 24 * 3600;
    let obj = {};

    return obj;
}

export function loadStats(cb) {

}

export const sumHours = (obj, hours, planHours, length) => {
    obj.splitHours = (obj.splitHours || 0) + (hours || 0) / (length || 1)
    obj.hours = (obj.hours || 0) + (hours || 0)
    obj.planHours = (obj.planHours || 0) + (planHours || 0)
    return obj;
}

export function calcStats(cb) {

    global.http.get('/hash-tag', {per_page: 10000, filter: {}}).then(hashTagsR => {


        global.http.get('/daily-task', {per_page: 10000, filter: {odb: {$exists: true}}}).then(r => {
            getTasks((tasks) => {
                let dayTasks = r.items;

                let obj = {}
                _.each(tasks, (item, ind) => {
                    let tags = item?.info?.tags || {}
                    obj[item._id] = {tags};
                })

                let odbs = {}
                _.each(dayTasks, (item, ind) => {
                    let {odb, task, hours, planHours} = item;
                    odbs[odb] ??= {categories: {}, total: {}}

                    let _sumHours = (obj, length) => {
                        return sumHours(obj, hours, planHours, length)
                    }

                    let taskTags = obj[task]?.tags || {}
                    _.each(taskTags, (item, catKey) => {
                        _.each(item, (tagKey, ind) => {
                            // let activityKey =
                            odbs[odb].categories[catKey] ??= {byTags: {}, total: {}}
                            odbs[odb].categories[catKey].byTags[tagKey] ??= {hours: 0, planHours: 0}
                            // odbs[odb].categories[catKey].byActivity[tagKey] ??= {hours: 0, planHours: 0}
                            _sumHours(odbs[odb].categories[catKey].byTags[tagKey], item?.length)
                            _sumHours(odbs[odb].categories[catKey].total, item?.length)
                        })
                    })
                    _sumHours(odbs[odb].total, 1)
                    odbs[odb] ??= {}
                })


                cb && cb({
                    odbs,
                    hashTags: hashTagsR.items,
                    hashTagsObj: objectify(hashTagsR?.items),
                    tasks,
                    obj,
                    dayTasks
                })
            })
        })
    })
}

const stringToColour = (str) => {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
    }
    return colour + '40'
}

export const getSum = (arr, odbs) => {
    let obj = {categories: {}, total: {}}

    let __sumHours = (obj, obj2) => {
        _.each(['hours', 'splitHours', 'planHours'], (item, ind) => {
            obj[item] = (obj[item] || 0) + (obj2[item] || 0)
        })
        return obj;

    }
    _.each(arr, (odb, ind) => {
        let stat = odbs[odb]
        if (stat) {
            console.log("qqqqq odbbbbbbbbbbbbb", stat);
            _.each(stat.categories, (item, category) => {
                obj.categories[category] ??= {byTags: {}, total: {}}
                _.each(item.byTags, (tagHours, tag) => {
                    obj.categories[category].byTags[tag] ??= {}
                    __sumHours(obj.categories[category].byTags[tag], stat.categories[category].byTags[tag])
                })
                __sumHours(obj.categories[category].total, stat.categories[category].total)
            })
            __sumHours(obj.total, stat.total)

        }

    })


    return obj;
}

function Layout2(props) {
    let {odbs, odb, hashTags, hashTagsObj} = props;
    console.log('*........ ## ROOT RENDERprops, hashTagsObj', props, hashTagsObj);

    let [dayTasks, setDayTasks] = useState([])
    let [tasksObj, setTasksObj] = useState({})
    let [tasks, setTasks] = useState([])
    let [stats, setStats] = useState([])
    let [open, setOpen] = useState({})

    let hashCategories = window?.info.hashCategories;
    let curOdb = new Date().toODB();

    if (!odbs) {
        return <>Loading ODBS...</>
    }

    let getOdbs = (odb, delta) => {
        let k = delta > 0 ? 1 : -1;
        let arr = []
        let cd = new Date().getTime();
        let day = 1000 * 24 * 3600;
        for (let i = 0; i < Math.abs(delta); i++) {
            arr.push(new Date(cd + i * day * k).toODB())
        }
        return _.sortBy(arr);
    }

    let periods = [{name: 'Сегодня', type: 'today', delta: -1}
        , {name: '7д', type: '7d', delta: -7}
        , {name: '30д', type: '30d', delta: -38}
        , {name: '90д', type: '90d', delta: -90}
        , {name: 'plan7', type: '-90d', delta: 7}
        , {name: 'plan120', type: '-90d', delta: 120}
    ]
    console.log("qqqqq odbsssssssssssss", odbs);
    return <div>
        <div>
            Period Selector {odb}
        </div>
        {(hashCategories || []).map((it, ind) => {

            let sum = (odbs[odb]?.categories || {})[it?._id] || {}

            return (<div key={ind} className={''}>
                {it?.name}
                <div></div>
                {(periods || []).map((itPeriod, ind) => {
                    let {type, name, delta} = itPeriod;
                    let arr = getOdbs(delta > 0 ? curOdb : odb, delta)
                    let sumParent = getSum(arr, odbs)
                    let sum = sumParent.categories[it?._id] || {}
                    let circle1 = {labels: [], data: [], colors: [], label: it?.name}
                    let detailsKey = [itPeriod.delta, it._id].join('_')

                    return (<div key={ind} className={'planCircleItem'}>

                        <a onClick={() => {
                            setOpen({...open, [detailsKey]: !open[detailsKey]})
                        }}>{name}</a>
                        <div></div>
                        {(Object.keys(sum?.byTags || []) || []).map((tagKey, ind) => {
                            let ht = hashTagsObj[tagKey] || {}
                            circle1.labels.push(sum.byTags[tagKey]?.hours + ' ' + ht?.name)
                            circle1.data.push(sum.byTags[tagKey]?.hours)
                            circle1.colors.push(ht?.color || stringToColour(Math.pow(ht?._id, 2).toString()))

                            return null;
                            return (<div key={ind}>
                                {ht?.name} {sum.byTags[tagKey]?.hours}
                            </div>)
                        })}
                        <CircleChart {...circle1}></CircleChart>

                        <MyModal
                            isOpen={open[detailsKey]}
                            onClose={() => {
                                setOpen({})
                            }}>
                            <>Periods:
                                <Stringify item={arr}></Stringify>
                                <div></div>
                                SumDetails:
                                <Stringify item={sum}></Stringify></>
                        </MyModal>
                    </div>)

                })}
                <hr/>
                {/*/!*{(Object.keys(sum.byTags) || []).map((tagKey, ind) => {*!/*/}
                {/*/!*    let ht = hashTagsObj[tagKey] || {}*!/*/}
                {/*/!*    circle1.labels.push(sum.byTags[tagKey]?.hours + ' ' + ht?.name)*!/*/}
                {/*/!*    circle1.data.push(sum.byTags[tagKey]?.hours)*!/*/}
                {/*/!*    circle1.colors.push(ht?.color || stringToColour(Math.pow(ht?._id, 2).toString()))*!/*/}

                {/*/!*    return (<div key={ind}>*!/*/}
                {/*/!*        {ht?.name} {sum.byTags[tagKey]?.hours}*!/*/}
                {/*/!*    </div>)*!/*/}
                {/*/!*})}*!/*/}
                {/*/!*<CircleChart {...circle1}></CircleChart>*!/*/}
                {/*<Stringify item={sum}></Stringify>*/}

            </div>)

        })}
        {/*<Stringify item={odbs}></Stringify>*/}
        {/*<LineChart></LineChart>*/}
        {/*<div className="LineChart"></div>*/}
    </div>
}

function CircleChart({labels, data, colors, label}) {
    const _data = {
        labels,
        datasets: [{
            label: label,
            data: data,
            backgroundColor: colors,
            hoverOffset: 4
        }]
    };

    return <div className={'pie'}>
        <Pie data={_data}/>
    </div>
}


const LineChart = () => {
    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Monthly Sales Data',
                data: [65, 59, 80, 81, 56, 55],
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
            },
            {
                label: 'Monthly S Data',
                data: [77, 59, 80, 81, 3, 55],
                fill: false,
                borderColor: 'rgb(192, 75, 186)',
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <div className='pie2'>
            <Line data={data} options={options}/>
        </div>
    );
};


export default Layout2
