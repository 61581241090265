import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import TaskItem from "./TaskItem";
import Button from "../../libs/Button";
import MyModal from "../../libs/MyModal";
import SprintEdit from "./SprintEdit";
import Smart from "../../libs/Smart";
import GroupButton from "../../libs/GroupButton/GroupButton";
import Stringify from "../Stringify";
import {getter} from "../methods/Fetcher";
import {getTasks, getTasksSync} from "./TaskSelector";

let sprintHttp = getter('/sprint')

function SprintList(props) {
    let {activeSprint, setSprintEdit, sprints, setSprints} = props
    return <>
        <a onClick={() => {
            setSprintEdit(activeSprint)
        }}>{props.label}</a>
        <Smart
            obj={{items: activeSprint?.planTasks}}
            onChange={({items}) => {
                console.log("qqqqq vvvvvvvvvvvv", items);
                activeSprint.planTasks = items;
                sprints.forEach((v, ind) => {
                    if (v._id == activeSprint._id) {
                        sprints[ind] = activeSprint
                    }
                })
                setSprints([...sprints])
                sprintHttp.update(activeSprint)
            }}
            items={[{
                key: 'items',
                size: 12,
                sortable: true,
                woAdd: true,
                each: [{
                    size: 12,
                    Component: ({localItem}) => {
                        return <div className={'2'}>
                            <TaskItem taskId={localItem?.task}></TaskItem>
                        </div>
                    }
                }]
            }
            ]}
        /></>
}


function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let completedTasks = [{}, {}]

    let [sprints, setSprints] = useState([])
    let [sprintEdit, setSprintEdit] = useState(null)
    // let [thanksItems, setThanskItems] = useState(null)
    //
    // function getThanksItems () {
    //     getTasks(items =>{
    //         let thanksItems = items.sort(it => {
    //             return (-1) * (+it?.info?.thanks || 0)
    //         })
    //     })
    //     setThanskItems(thanksItems.slice(0, 10))
    // }

    let thanksItems = _.sortBy(getTasksSync().filter(it => {
        return +it?.info?.thanks || /done|archive/gi.test(it?.info?.status)
    }), it => {
        return (-1) * (+it?.info?.thanks || 0)
    }).slice(0, 10)
    let urgentItems = _.sortBy(getTasksSync().filter(it => {
        return +it?.info?.urgent
    }), it => {
        return (-1) * (+it?.info?.urgent || 0)
    }).slice(0, 10)

    // useEffect(getThanksItems, [])
    useEffect(() => {
        console.log("qqqqq aaaaaaaaaaaaaaaaaaaaaaaaaarrrrrrrrrrrrr4444444444444444",);
        sprintHttp.get({per_page: 1000}, (items) => {
            setSprints(items)
        })
    }, []);

    let info = {
        yearly: [{}, {}],
        monthly: [{}, {}],
        thanks: [{}, {}]
    }

    let odb = new Date().toODB();
    let activeSprint = sprints.find(it => {
        if (odb >= it.startOdb && odb <= it.endOdb) {
            return true;
        }
    })

    let ySprint = sprints[2]
    let mSprint = sprints[2]

    console.log("qqqqq SPRINT INFO BLOCK RENDER",);

    return <div>
        <div className="col-xs-6">
            <SprintList
                label={'PlanTasks'}
                setSprintEdit={setSprintEdit}
                activeSprint={activeSprint} sprints={sprints} setSprints={setSprints}></SprintList>
            <a>CompletedTasks ({completedTasks?.length}):</a>
            <hr/>
            Urgent

            <ListItems items={urgentItems}></ListItems>
            <hr/>
            <Button onClick={(scb) => {
                scb && scb();
                setSprintEdit({})
            }}>Добавить Спринт</Button>
            Страница спринтов
            {(sprints || []).map((it, ind) => {
                return (<div key={ind} onClick={() => {
                    setSprintEdit(it)
                }}>
                    {it.name}
                </div>)
            })}


        </div>
        <div className="col-xs-6">

            <SprintList
                label={"Yearly"}
                setSprintEdit={setSprintEdit}
                activeSprint={ySprint}
                sprints={sprints} setSprints={setSprints}></SprintList>

            <SprintList
                label={"Mo"}
                setSprintEdit={setSprintEdit}
                activeSprint={mSprint}
                sprints={sprints} setSprints={setSprints}></SprintList>
            <hr/>
            <GroupButton items={['', 'date', 'h', 'h6', 'h12']}></GroupButton>
            Thanks:
            <ListItems items={thanksItems}></ListItems>
        </div>


        <MyModal
            isOpen={sprintEdit}
            onClose={() => {
                setSprintEdit(false)
            }}
        >
            <SprintEdit item={sprintEdit} onChange={(v) => {
                setSprintEdit(v)
                if (v) {
                    setSprints(items => {
                        let goodItem;
                        _.each(items, (item, ind) => {
                            if (item._id == v._id) {
                                items[ind] = v;
                                goodItem = true;
                            }
                        })
                        if (!goodItem) {
                            items.push(v)
                        }

                        return items;
                    })
                }
            }}></SprintEdit>
        </MyModal>

    </div>
}

function ListItems(props) {
    let items = props?.items || [];
    return <Smart
        obj={{items}}
        onChange={(v) => {
            console.log("qqqqq vvvvvv", v);
        }}
        items={[{
            key: 'items',
            size: 12,
            woDel: true,
            woRem: true,
            // sortable: true,
            woAdd: true,
            each: [{
                size: 12,
                Component: ({localItem}) => {
                    console.log("qqqqq localItems", localItem);
                    return <div className={'2'}>
                        <TaskItem taskId={localItem?._id}></TaskItem>
                    </div>
                }
            }]
        }
        ]}
    />

}


export default Layout2
