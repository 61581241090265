import React from 'react';
import { Link } from "react-router-dom";
import Smart from 'libs/Smart';

function Layout2(props) {
    let { item } = props.props || props;
    let list = (global.parentObj || {})[item._id] || [];
    let list2 = ((global.compObj || {})[item._id] || {}).childs || [];
    console.log('*........ ## PARENT ROO ROOT RENDER', item, list, list2);

    return <div>
        <hr />
        <small>
            {/*<Smart item={global.compObj}></Smart>*/}
            {/*<Stringify item={global.compObj}></Stringify>*/}
            <div>
                <span>Parent: </span>
                {(list || []).map((_id, ind) => {
                    let point = global.compObj[_id]
                    return (<Link to={'/components/' + point._id} key={ind} className={'ib mr-10'}>
                        {point.name}
                    </Link>)
                })}
            </div>
            <div>
                <span>Childs: </span>
                {(list2 || []).map((_id, ind) => {
                    let point = global.compObj[_id]
                    return (<Link to={'/components/' + point._id} key={ind} className={'ib mr-10'}>
                        {point.name}
                    </Link>)
                })}
            </div>


        </small>
    </div>
}

export default Layout2
