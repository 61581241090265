import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "../Stringify";
import Button from "../../libs/Button";
import SprintEdit from "./SprintEdit";
import MyModal from "../../libs/MyModal";
import DailyTasks from "./DailyTasks";
import DaysSelector from "./DaysSelector";
import DaysStats, {calcStats} from "./DaysStats";
import SprintInfoBlock from "./SprintInfoBlock";
import {deleteTask, getTasksSync, saveTaskDetails} from "./TaskSelector";
import ZadachiEdit from "../Main/ZadachiEdit";


function Layout2(props) {


    let [filter, _setFilter] = useState({
        odb: new Date().toODB()
    })
    let [info, setInfo] = useState({})
    let [cd, setCd] = useState(false)
    useEffect(() => {
        loadStats()
    }, [])

    let loadStats = () => {
        calcStats(r => {
            console.log("qqqqq calced stats", r);
            setInfo(r)
        })
    }
    window.sprintComponentRerender = () => setCd(new Date())
    window.sprintComponentCd = cd;

    let setFilter = (v) => {
        _setFilter({...filter, ...v})
    }

    window.odbs = info?.odbs;

    let editTask = getTasksSync()[0];


    return <div>
        <div className="row">

            <div className="col-xs-8">
                <div className="ib">
                <Button onClick={(scb) => {
                    scb && scb();
                    loadStats()
                }}>ReLoad Stats</Button>
                </div>
                <DaysSelector
                    odbs={info?.odbs}
                    onClick={(odb) => {
                        setFilter({odb})
                    }}
                    filter={filter} setFilter={setFilter}></DaysSelector>
                <hr/>
                <DailyTasks

                    filter={filter}
                    setFilter={setFilter}
                ></DailyTasks>
                <hr/>
                <DaysStats
                    odb={filter.odb}
                    odbs={info?.odbs}
                    hashTags={info?.hashTags}
                    hashTagsObj={info?.hashTagsObj}
                    filter={filter} setFilter={setFilter}></DaysStats>
            </div>
            <div className="col-xs-4">
                <SprintInfoBlock></SprintInfoBlock>
            </div>
            <div className="col-xs-12">
                <hr/>
            </div>
        </div>


    </div>
}

export default Layout2
