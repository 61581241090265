import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import Categories from "./Categories";
import Storage from './../../libs/Storage/Storage'
import Stringify from "../Stringify";
import {getHash, pubCategory, setHash} from "./methods";
import AuthorsConfig from "./AuthorsConfig";
import Zametki from "./Zametki";
import Zadachi from "./Zadachi";
import Input from "../../libs/Input";
import MyAutocomplete from "./MyAutocomplete";
import MyAutoComplete from "./MyAutocomplete";
import Select from "../../libs/Select";
import GroupButton from "../../libs/GroupButton/GroupButton";
import {STATUS_ITEMS} from "./ZadachiStatuses";

function Main(props) {

    let [filter, setFilter] = useState(getHash() || {})

    useEffect(() => {
        setHash(filter)
    }, [JSON.stringify(filter)])

    let arr = (filter?.view || 'v123').replace('v', '').split('')

    return <div className={'mainFix view-' + arr.join(' view-')} data-view={filter.view}
                data-desc-size={filter.descSize}>
        <div className="row">

            <div className="col-xs-3 childview1">
                <Categories filter={filter} setFilter={setFilter}></Categories>
            </div>
            <div className="col-xs-9 top childWrapTop">
                <div className="pull-right">
                    <a onClick={() => {setFilter({...filter, isHide: !filter.isHide})}}>hide</a>
                </div>
                {!filter?.isHide && <>
                    <div className="ib">
                        <GroupButton value={filter.sort} items={
                            ['date', 'fastprofit', 'profit', 'myHours', 'profitTime'].map(it => ({name: it, value: it}))
                        } onChange={sort => {
                            setFilter({...filter, sort})
                        }}></GroupButton>
                    </div>
                    <div className="ib ml-5">
                        <GroupButton
                            value={filter.descSize}
                            items={
                                ['desc', 'descShort', 'noDesc', 'superShort'].map(it => ({name: it, value: it}))
                            }
                            onChange={descSize => {
                                setFilter({...filter, descSize})
                            }}>
                        </GroupButton>
                    </div>
                    <div className="ib ml-5">
                        <GroupButton
                            value={filter.view}
                            items={
                                ['v2', 'v12', 'v123', 'v23', 'v13', 'v3'].map(it => ({name: it, value: it}))
                            }
                            onChange={view => {
                                setFilter({...filter, view})
                            }}>
                        </GroupButton>
                    </div>
                    <div className="ib" style={{width: '250px'}}>
                        <MyAutoComplete localItem={filter} field={{isMulti: true, key: 'authors', itemsKey: 'authors'}}
                                        onChange={authors => {
                                            setFilter({...filter, authors})
                                        }}></MyAutoComplete>
                    </div>
                    <div></div>
                    <div className="ib">
                        <Input value={filter.search} onChange={search =>
                            setFilter({...filter, search})} placeholder={'Поиск'}></Input>
                    </div>
                    <div className="ib" style={{marginTop: '30px'}}>
                        <div className="ib ml-5">
                            <GroupButton
                                value={filter.status}
                                items={
                                    [{
                                        name: 'Все',
                                        value: ''
                                    }, {
                                        name: 'Актив',
                                        value: 'notArchive'
                                    }, ...STATUS_ITEMS]
                                }
                                onChange={status => {
                                    setFilter({...filter, status})
                                }}>
                            </GroupButton>
                        </div>
                    </div>
                    <hr/>

                </>}


                <div className="col-xs-6 childview2">
                    {/*Задачи для категории {pubCategory(filter.category)}*/}
                    <Zadachi filter={filter}></Zadachi>
                </div>
                <div className="col-xs-6 childview3">
                    {/*Заметки*/}
                    <div className="pull-right">
                        <AuthorsConfig></AuthorsConfig>
                    </div>
                    <Zametki filter={filter} setFilter={setFilter}></Zametki>
                </div>
            </div>

        </div>
    </div>
}

export default Main
