import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import TaskItem from "./TaskItem";
import Input from "../../libs/Input";
import Smart from "../../libs/Smart";
import GroupButton from "../../libs/GroupButton/GroupButton";
import MyModal from "../../libs/MyModal";
import Datepicker from "../../libs/Datepicker/Datepicker";
import {getter} from "../methods/Fetcher";
import TaskSelector, {deleteTask, getTaskDetailsSync, getTasks, getTasksSync, saveTaskDetails} from "./TaskSelector";
import ZadachiEdit, {stages} from "../Main/ZadachiEdit";
import {STATUS_ITEMS} from "../Main/ZadachiStatuses";
import Stringify from "../Stringify";

let dt = getter('/daily-task');
let taskHttp = getter('/task');

function TaskWrap(props) {
    let {taskId} = props;
    let [item, setItem] = useState(null)

    useEffect(() => {
        taskId && getTasks(items => {
            setItem(items.find(it => it._id == taskId))
        })
    }, [taskId])


    return <>
        Task Wrap

        {item && <ZadachiEdit item={item} onChange={(v) => {
            setItem({...v})
        }} onClose={v => {
            if (!v) {
                return deleteTask(item)
            }
            console.log("qqqqq vvvvvvvvvvvvvvvvv", v);
            saveTaskDetails(v, () => {

            }, true)

        }}></ZadachiEdit>}
    </>
}


function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let {filter, setFilter} = props || {}
    let [items, setItems] = useState([])
    let [editTask, setEditTask] = useState(null)
    let [loading, setLoading] = useState(false)
    let [dayTaskEdit, setDayTaskEdit] = useState(false)
    let [dayTaskCache, setDayTaskCache] = useState(false)
    let sorting = ['', 'hours', 'category']
    // let items = [{}, {}, {}]


    let task = getTasksSync()[0];
    useEffect(() => {
        // setEditTask(task)
    }, [task?._id])

    useEffect(() => {
        sync()
    }, [filter.odb])

    let sync = () => {
        setLoading(true)
        dt.get({per_page: 10000, filter: {odb: filter.odb}}, r => {
            console.log("qqqqq tttttttttttttttttttttttttt", r);
            setLoading(false)
            setItems(r)
        })
    }
    let recalc = (scb) => {
        console.log("qqqqq recalc", scb);
        scb && scb()
    }

    let createDayTaskHttp = (scb, opts) => {

        dayTaskEdit = opts?.dayTaskEdit || dayTaskEdit;
        let isSync = opts?.isSync;
        items = items || []

        let onUpdate = (r) => {
            let isFound = false;
            _.each(items, (item, ind) => {
                if (item._id == r._id) {
                    isFound = true;
                    items[ind] = r;
                }
            })
            if (!isFound) {
                items.unshift(r)
            }
            scb && scb();

            setItems([...items])
            setDayTaskEdit(false)

            if (isSync) {
                sync()
            }
        }
        let isNew = !dayTaskEdit?._id
        if (isNew) {
            setDayTaskCache({...dayTaskEdit})
            dt.create(dayTaskEdit, onUpdate)
        } else {
            dt.update(dayTaskEdit, onUpdate)
        }
    }

    let rem = (scb) => {
        scb && scb();
        dt.delete(dayTaskEdit, () => {
            setDayTaskEdit(false)
            sync()
        })
    }
    let move = (scb, delta, isNew) => {
        let day = 24 * 3600 * 1000;
        let odb = new Date(new Date(dayTaskEdit.odb).getTime() + delta * day).toODB();
        dayTaskEdit.odb = odb;

        console.log("qqqqq sssssssssssssssssssssss",);
        if (isNew) {
            dayTaskEdit = JSON.parse(JSON.stringify(dayTaskEdit));
            delete dayTaskEdit._id;
        }

        console.log("qqqqq sssssssssssssssssssssss", dayTaskEdit);
        createDayTaskHttp(scb, {dayTaskEdit, isSync: true})


    }

    let isNew = !dayTaskEdit?._id
    if (dayTaskEdit) {
        dayTaskEdit.info ??= {}
        dayTaskEdit.info.stagesInfo ??= {}
    }

    let stagesInfo = dayTaskEdit?.info?.stagesInfo

    let _onChange = () => {

        setDayTaskEdit({...dayTaskEdit})

    }

    return <div className={loading ? 'o5' : ''}>
        <MyModal
            isOpen={!!dayTaskEdit}
            onClose={() => setDayTaskEdit(false)}
        >
            <Button onClick={createDayTaskHttp}>{isNew ? 'Создать ДТ' : 'Обновить ДТ'}</Button>
            <hr/>
            {dayTaskEdit?.odb}
            <div>
                <GroupButton
                    items={STATUS_ITEMS}
                    value={dayTaskEdit?.status}
                    item={dayTaskEdit.status} onChange={(v) => {
                    dayTaskEdit.status = v;
                    _onChange()
                }}></GroupButton>
                {dayTaskEdit?.status || '-'}
            </div>
            <Input
                autofocus={true} value={dayTaskEdit?.name} onChange={(name) => {
                setDayTaskEdit({...dayTaskEdit, name})
            }}></Input>
            {dayTaskEdit?.task && <a onClick={v => {
                let task = getTaskDetailsSync(dayTaskEdit?.task)
                setDayTaskEdit({...dayTaskEdit, name: task?.name})
            }}>Pull Name from Task</a>}
            <Datepicker value={dayTaskEdit?.odb} onChange={(odb) => {
                console.log("qqqqq odbbbb", odb);
                setDayTaskEdit({...dayTaskEdit, odb: new Date(odb).toODB()})
            }}></Datepicker>
            {/*<Input value={dayTaskEdit?.hours} onChange={(hours) => {*/}
            {/*    setDayTaskEdit({...dayTaskEdit, hours})*/}
            {/*}}></Input>*/}
            {/*<Input value={dayTaskEdit?.planHours} onChange={(planHours) => {*/}
            {/*    setDayTaskEdit({...dayTaskEdit, planHours})*/}
            {/*}}></Input>*/}

            <hr/>
            <strong>Hours: {dayTaskEdit.hours} PlanHours: {dayTaskEdit.planHours}</strong>
            {/*{dayTaskEdit?.task}*/}
            <TaskSelector task={dayTaskEdit?.task} onChange={(task, taskObj) => {
                let stagesInfo = taskObj?.info?.stagesInfo || {}
                dayTaskEdit.info ??= {}
                dayTaskEdit.info.stagesInfo = stagesInfo

                console.log("qqqqq stagesInfo", stagesInfo);
                setDayTaskEdit({
                    ...dayTaskEdit, task,

                    name: dayTaskEdit?.name || taskObj?.name
                })
            }}></TaskSelector>
            <hr/>
            {!!stagesInfo && (stages || []).map((it, ind) => {
                let key = it.key;
                stagesInfo[key] = stagesInfo[key] || {}
                let stInfo = stagesInfo[key]
                return (<div key={ind} className={(+stInfo?.planHours || +stInfo?.hours) ? '' : 'o5'}
                             style={{marginTop: '10px'}}>
                    <div>
                        {it?.name}
                    </div>

                    <div className="ib" style={{width: '60px'}}>
                        <Input
                            type={'number'}
                            placeholder={'PHours'} value={stInfo?.planHours} onChange={(v) => {
                            stInfo.planHours = v;
                            _onChange()
                        }}></Input>
                    </div>
                    <div className="ib" style={{width: '60px'}}>
                        <Input
                            type={'number'}
                            placeholder={'hours'} value={stInfo?.hours} onChange={(v) => {
                            stInfo.hours = v;
                            _onChange()
                        }}></Input>
                    </div>

                    <div className={'ib'}>
                        <GroupButton
                            value={stInfo.status}
                            items={STATUS_ITEMS}
                            onChange={(v) => {
                                stInfo.status = v;
                                _onChange()
                            }}
                        ></GroupButton>
                    </div>
                </div>)
            })}
            <hr/>
            <Button onClick={(scb) => {
                move(scb, -1)
            }}>Move To Prev Day</Button>
            <Button onClick={(scb) => {
                move(scb, 1)
            }}>Move To Next Day</Button>
            <Button onClick={(scb) => {
                move(scb, 1, true)
            }}>Copy To Next Day</Button>
            <Button onClick={(scb) => {
                rem(scb)
            }}>Remove</Button>
            <hr/>
            <Button onClick={createDayTaskHttp}>{isNew ? 'Создать ДТ' : 'Обновить ДТ'}</Button>
            <hr/>
            <div>
                <TaskWrap taskId={dayTaskEdit?.task}></TaskWrap>
            </div>
        </MyModal>
        <Button onClick={(scb) => {
            scb && scb()
            setDayTaskEdit({...dayTaskCache || {}, name: '', odb: filter.odb})
        }}>+ DTask</Button>
        <Button onClick={(scb) => {
            scb && scb()
            setEditTask({start: new Date(filter.odb)})
        }}>+ Task</Button>
        <MyModal
            size={'lg'}
            isOpen={editTask}
            onClose={() => setEditTask(null)}
        >
            <ZadachiEdit item={editTask} onChange={(v) => {
                setEditTask(v)
            }} onClose={(v) => {
                if (!v) {
                    return deleteTask(editTask)
                }
                console.log("qqqqq vvvvvvvvvvvvvvvvv", v);
                saveTaskDetails(v, setEditTask, true)
                taskHttp.smartSave(v, (r) => {
                })
            }}></ZadachiEdit>
        </MyModal>
        <div className="ib mtlabel">
            <Input placeholder={'Тотал часов'}></Input>
        </div>
        <div className="ib">
            <Button size={''} color={1} onClick={(scb) => {
                recalc(scb)
            }}>Калк</Button>
        </div>
        <div className="ib">
            <GroupButton items={sorting}
                         size={'md'}
                         filter={filter.sort}
                         onChange={v => setFilter({...filter, ['sort']: v})}></GroupButton>
        </div>
        <Smart
            obj={{items}}
            onChange={(v) => {
                console.log("qqqqq vvvvvv", v);
                setItems([...v.items])
            }}
            onCreate={() => {
            }}
            items={[{
                key: 'items',
                size: 12,
                sortable: true,
                woAdd: true,
                woRem: true,
                each: [{
                    size: 12,
                    Component: ({localItem}) => {
                        let item = localItem;
                        return <div className={'row'} onClick={() => {
                            setDayTaskEdit(item)
                        }}>
                            <div className="col-xs-1">{item?.hours || '-'}h {item.planHours && <>({item.planHours}h)</>}

                            </div>
                            <div className="col-xs-5">
                                {item?.name}
                                <div>
                                    {item?.status == 'auto' && <div className="label label-success">{item?.status}</div>}
                                </div>
                            </div>
                            <div className="col-xs-4">
                                <TaskItem
                                    autoOpen={true}
                                    taskId={item?.task} withStatus={true}></TaskItem>
                            </div>
                        </div>
                    }
                }]
            }
            ]}
        />

        {/*{(items || []).map((it, ind) => {*/}
        {/*    return (<div key={ind} className={'dailyTasks row'}>*/}
        {/*        <div className="col-xs-1">1h (2h)*/}

        {/*        </div>*/}
        {/*        <div className="col-xs-5">*/}
        {/*            Задание по выполнению истории*/}
        {/*            <div>*/}
        {/*                <div className="label label-success">План</div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="col-xs-4">*/}
        {/*            <TaskItem withStatus={true}></TaskItem>*/}
        {/*        </div>*/}

        {/*    </div>)*/}
        {/*})}*/}


    </div>
}

export default Layout2
