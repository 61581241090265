import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import MyModal from "../../libs/MyModal";
import Smart from "../../libs/Smart";
import SaveUpdate from "./SaveUpdate";
import {pubAuthor, pubCategory} from "./methods";
import Stringify from "../Stringify";
import {getODBByInfo} from "./ZadachiDate";
import {STATUS_ITEMS, THANKS_ITEMS} from "./ZadachiStatuses";
import TaskSelector, {getTasks} from "../Sprint/TaskSelector";
import Input from "../../libs/Input";
import GroupButton from "../../libs/GroupButton/GroupButton";
import Datepicker from "../../libs/Datepicker/Datepicker";

export const stages = [
    {name: 'Анализ', key: 'analyse', color: ''},
    {name: 'Планирование', key: 'plan'},
    {name: 'Дилегирование', key: 'dilegate'},
    {name: 'Контроль', key: 'control'},
    {name: 'Собственное выполнение', key: 'complete'},
    {name: 'Ретро', key: 'retro'},
    {name: 'Контроль / фиксация', key: 'double_control'},
]

function ZadachiEdit(props) {
    let [it, setIt] = useState(null)
    let {item, onChange, onClose} = props;

    useEffect(() => {
        setIt({...item})
    }, [JSON.stringify(item)])


    let canCannot = [{
        name: 'Могу', value: 1
    }, {
        name: 'Не могу', value: -1
    }];
    let yesNo = [{
        name: '-', value: 0
    }, {
        name: 'Да', value: 1
    }, {
        name: 'Нет', value: -1
    }];

    let yesNo2 = [...yesNo, {name: 'Уже', value: 2}]
    let canCannot2 = [...canCannot, {name: 'Найм и могу', value: -2}]

    let HR = {type: 'HR', size: 12,}
    let SPACE = {size: 12, type: 'text', value: ' '}

    let RETRO = [
        // {type: 'text', value: 'Какая метрика'},
        {
            key: 'good', label: 'Что хорошо', type: 'textarea', minRows: 1, woLabel: true
        }, {
            key: 'bad', label: 'Что плохо', type: 'textarea', minRows: 1, woLabel: true
        },
    ]


    return <div>
        <SaveUpdate
            postfix={' Задачу'}
            item={it}
            onChange={(v) => onClose(v)}
            url={'task'}
            field={'tasks'}
        ></SaveUpdate>
        <hr/>
        <Smart
            defSize={6}
            obj={it}
            onChange={(v) => onChange({...v})}
            items={[
                {
                    name: 'Название', key: 'name',
                },
                {
                    name: 'Описание', key: 'desc', type: 'textarea', minRows: 1
                }, {
                    size: 12,
                    name: 'Критерии что получилось', key: 'info.solutionCreteria', type: 'textarea', minRows: 1
                }, {
                    label: 'Создавть процесс в будущем', key: 'info.isFutureProcess', type: 'group', items: yesNo2
                },
                {
                    name: '% выполнения', key: 'info.perc', type: 'number',
                },
                {
                    label: 'Статус???',
                    key: 'info.status', type: 'group', items: STATUS_ITEMS
                },
                // {
                //     label: 'IsThanks',
                //     key: 'info.isThanks', type: 'checkbox'
                // },
                // {
                //     label: 'IsThanks',
                //     key: 'info.isThanks', type: 'checkbox'
                // },
                {
                    label: 'Thanks',
                    key: 'info.thanks', type: 'group', items: THANKS_ITEMS
                }, {
                    label: 'Urgent',
                    key: 'info.urgent', type: 'group', items: THANKS_ITEMS
                },
                {
                    label: 'ParentTask',
                    key: 'parentTask',
                    Component: ({item, onChange}) => {
                        return <TaskSelector task={item?.parentTask} onChange={(parentTask) => {
                            onChange({...item, parentTask})
                        }}>></TaskSelector>
                    }
                }, {
                    label: 'ParentTask',
                    key: 'parentTask',
                    Component: ({item, onChange}) => {
                        return <div>Дерево родительских и дочерних задач</div>
                    }
                },
                HR,

                // {label: 'start', type: 'date', key: 'start', size: 4},
                // {label: 'end', type: 'date', key: 'end', size: 4},
                // {label: 'ping', type: 'date', key: 'ping', size: 4},
                // HR,
                {
                    size: 12,
                    childSize: 6,
                    path: 'Sprint/MainGroups'
                },
                HR,

                {
                    label: 'Переодичность',
                    key: 'info.periodType', type: 'group',
                    def: 'process',
                    items: [{
                        name: 'Процесс', value: 'process'
                    }, {
                        name: 'Процесс Гибкий', value: 'flex_process'
                    }, {
                        name: 'Разовая', value: 'one'
                    }]
                },
                {
                    Component() {
                        return <>
                            <div className={'label label-default'}>{getODBByInfo(it?.info, -1)}</div>
                            <div className={'label label-default'}>{getODBByInfo(it?.info)}</div>
                        </>
                    }
                },
                {
                    size: 12,
                    isVisible: () => {
                        return it?.info?.periodType == 'process'
                    },
                    childs: [
                        HR,
                        {
                            label: 'Переодичность',
                            key: 'info.period', type: 'group', items: [
                                // {name: 'Неделя', value: 'w'},
                                {name: 'X недель', value: 'xw'},
                                {name: 'X Месяц', value: 'xm'},
                                // {name: 'Квартал', value: 'q'},
                            ]
                        }, {
                            label: 'День',
                            size: 2,
                            key: 'info.dayN', type: 'number'
                        }, {
                            isVisible: () => {
                                return /xw|xm/gi.test(it?.info?.period)
                            },
                            label: 'Каждый N-й',
                            size: 2,
                            key: 'info.xN', type: 'number'
                        }, {
                            label: 'Старт',
                            size: 2,
                            isVisible: () => {
                                return /xm/gi.test(it?.info?.period)
                            },
                            key: 'info.xStartN', type: 'input'
                        },
                        // {
                        //     size: 12,
                        //     sortable: true,
                        //     key: 'itemsPeriod',
                        //     each: [{
                        //         key: 'date', label: 'План дата', type: 'date',
                        //         size: 3,
                        //     }, {
                        //         key: 'remindDays', label: 'Напоминить', type: 'number',
                        //         size: 3,
                        //     },
                        //         {
                        //             key: 'rate',
                        //             label: 'Оценка',
                        //             type: 'group',
                        //             items: [1, 2, 3, 4, 5].map(it => ({name: it, value: it}))
                        //         },
                        //         SPACE,
                        //         {
                        //             key: 'goals', label: 'Цели & Критерии достижения', type: 'textarea', minRows: 1
                        //         }, {
                        //             label: '5 шагов делать - не делать',
                        //             key: 'validateion', type: 'group', items: yesNo
                        //         },
                        //         SPACE,
                        //         ...RETRO
                        //     ]
                        // },
                    ]
                },
                {
                    size: 12,
                    isVisible: () => {
                        return (it?.info?.periodType || 'one') == 'flex_process'
                    },
                    childs: [
                        HR,
                        {
                            label: 'Черех Х Дней',
                            size: 2,
                            key: 'info.daysDelay', type: 'number'
                        }
                    ]
                },
                HR,
                {
                    size: 12,
                    Component: StagesComp
                },

                // {
                //     size: 12,
                //     isVisible: () => {
                //         return (it?.info?.periodType || 'one') == 'one'
                //     },
                //     childs: [
                //         HR,
                //         {
                //             size: 12,
                //             sortable: true,
                //             key: 'items',
                //             each: [
                //                 {
                //                     label: 'Статус',
                //                     size: 9,
                //                     key: 'status', type: 'group', items: [
                //                         {name: 'Жду', value: 'wait'},
                //                         {name: 'Пинг', value: 'ping'},
                //                         {name: 'Прогресс', value: 'process'},
                //                         {name: 'Ревью', value: 'review'},
                //                         {name: 'Сделано', value: 'done'},
                //                         {name: 'Не получилось', value: 'cannot'},
                //                     ]
                //                 }, {
                //                     size: 3,
                //                     label: '5 шагов?',
                //                     key: 'validateion', type: 'group', items: yesNo
                //                 }, {
                //                     key: 'name', label: 'Название'
                //                 }, {
                //                     key: 'criteria', label: 'Критерии что получилось', type: 'textarea', minRows: 1
                //                 }, SPACE, {
                //                     key: 'date', label: 'План дата', type: 'date'
                //                 }, {
                //                     key: 'pingDate', label: 'Пинг даат', type: 'date'
                //                 }, {
                //                     key: 'remindDays', label: 'Напомнить дней', type: 'number'
                //                 }, {
                //                     key: 'comment', label: 'Коммент', type: 'textarea', minRows: 1
                //                 },
                //                 SPACE,
                //                 ...RETRO
                //             ]
                //         },
                //     ]
                // },
                HR,

                {
                    defSize: 4,
                    size: 12,
                    childs: [
                        {type: 'text', value: 'Делать не делать'},
                        {
                            defSize: 6, size: 8, childs: [
                                {
                                    label: 'Тип',
                                    size: 12,
                                    key: 'info.systemOrOne', type: 'group', items: [{
                                        name: 'Контроль', value: 'control'
                                    }, {
                                        name: 'Развитие', value: 'razvitie'
                                    }, {
                                        name: 'Поддержание', value: 'current'
                                    }]
                                },
                                {
                                    type: 'textarea', key: 'info.work1metric', name: 'Какую метрику на сколько',
                                    size: 12, minRows: 1
                                },
                                {type: 'number', key: 'info.work1custdev', name: 'Корр. Каст дев'},
                                {
                                    label: 'Стоимость м.',
                                    key: 'info.priceToImplement',
                                    type: 'number'
                                },
                                SPACE,
                                {

                                    label: 'Профит 3м',
                                    key: 'info.profit3',
                                    size: 4,
                                    type: 'number'
                                }, {
                                    label: 'Профит 6м',
                                    key: 'info.profit6',
                                    size: 4,
                                    type: 'number'
                                }, {
                                    label: 'Профит 24м',
                                    key: 'info.profit24',
                                    size: 4,
                                    type: 'number'
                                }, SPACE,

                                {
                                    label: 't сам',
                                    key: 'info.myWorkTime',
                                    type: 'number',
                                    size: 3,
                                }, {
                                    label: '% сам',
                                    key: 'info.myPerc',
                                    type: 'number',
                                    size: 3
                                },
                                {
                                    size: 6,
                                    childs: [{
                                        label: 't команды',
                                        key: 'info.teamWorkTime',
                                        type: 'number',
                                        size: 6,
                                    }, {
                                        label: '% команда',
                                        key: 'info.teamPerc',
                                        type: 'number',
                                        size: 6
                                    },

                                        {
                                            label: 't контроль',
                                            key: 'info.teamWorkTimeControl',
                                            type: 'number',
                                            size: 12,
                                        },
                                    ]
                                },


                            ]
                        }


                    ]
                }, {
                    defSize: 4,
                    size: 12,
                    childs: [
                        {type: 'text', value: 'Делать сейчас / потом'},
                        {
                            size: 8,
                            defSize: 6,
                            childs: [
                                {
                                    label: 'Актуальность сейчас?',
                                    key: 'info.actuality', type: 'group', items: canCannot
                                },
                                {type: 'text', value: 'Приоритет суммарный хорош?'},

                            ]
                        },
                    ]
                }, {
                    defSize: 4,
                    size: 12,
                    childs: [
                        {type: 'text', value: 'Делать самому'},
                        {
                            label: 'Могу дилегировать',
                            key: 'info.canDiligate', type: 'group', items: canCannot2
                        },
                        {
                            label: 'Эффект',
                            key: 'info.systemOrOne', type: 'group', items: [{
                                name: 'Системный', value: 'system'
                            }, {
                                name: 'Разовый', value: 'one'
                            }]
                        },
                        {
                            label: 'Шаг текущий или +1?',
                            key: 'info.nowOr1', type: 'group', items: [{
                                name: 'Сейчас', value: 'now'
                            }, {
                                name: '+1', value: '+1'
                            }]
                        }

                    ]
                }, {
                    defSize: 4,
                    size: 12,
                    childs: [
                        {type: 'text', value: 'Делать в этом объеме'},
                        {
                            type: 'textarea',
                            woLabel: true,
                            size: 8,
                            minRows: 1,
                            label: '3 альтернативы объема! Мало профит = мин объем',
                            key: 'info.obematernative'
                        },
                    ]
                }, {
                    defSize: 4,
                    size: 12,
                    childs: [
                        {type: 'text', value: 'Делать этим способом'},
                        {
                            size: 8,
                            defSize: 12,
                            childs: [
                                // {type: 'text', value: 'Какая метрика'},
                                {
                                    type: 'textarea',
                                    woLabel: true,
                                    key: 'info.alternatives',
                                    name: 'Еще 3 варианта как поднять эту метрику',
                                    minRows: 1
                                },
                            ]
                        }
                    ]

                }, {
                    defSize: 4,
                    size: 12,
                    childs: [
                        {type: 'text', value: 'Ретро'},
                        {
                            size: 8,
                            defSize: 6,
                            childs: RETRO
                        }
                    ]

                },

                HR,
                {
                    name: 'Родит задачи', key: 'info.parentTasks',
                }, {
                    name: 'Возможности', key: 'info.opportunities', type: 'textarea', minRows: 1
                },


                {
                    name: 'Img', key: 'img', type: 'textarea', minRows: 1, size: 12,
                },

                {

                    isMulti: true,
                    path: 'Main/MyAutocomplete',
                    key: 'categories',
                    itemsKey: 'categories',
                    label: 'Категория',
                },
                {
                    isMulti: true,
                    path: 'Main/MyAutocomplete',
                    key: 'info.inspireAuthor',
                    itemsKey: 'authors',
                    label: 'Вдохновитель',
                },
            ]}
        >

        </Smart>
        <hr/>
        <SaveUpdate
            item={it}
            onChange={(v) => onClose(v)}
            url={'task'}
            field={'tasks'}
        ></SaveUpdate>
    </div>
}

export const DAY = 24 * 3600 * 1000;

function StagesComp({item, localItem, onChange}) {
    item.info ??= {}
    item.info.stagesInfo ??= {}
    let info = item.info;
    let isPeriod = /process/gi.test(info.periodType);
    let stagesInfo = item?.info?.stagesInfo || {}

    let _onChange = () => {
        let lastCd = new Date().getTime();
        _.each(stages, (item, ind) => {
            let key = item.key;
            stagesInfo[key] ??= {}
            let stInfo = stagesInfo[key];

            if (stInfo.planHours) {
                lastCd = stInfo.startForce ? new Date(stInfo.startForce).getTime() + .5 * DAY : (lastCd + (stInfo.days || 0) * DAY)
                stInfo.startOdb = new Date(lastCd).toODB()
            }
        })
        onChange(item)
    }

    let sumHours = 0;
    _.each(stages, (item, ind) => {
        sumHours += +stagesInfo[item.key]?.planHours || 0
    })

    function gen(scb) {
        global.http.put('/task', {item}).then(() => {
            global.http.get('/generate-day-auto-tasks', {_id: item._id}).then(r => {
                console.log("qqqqq rrrrrrrrrrrrrrrrrrrrr gen", r);
                scb && scb()
            })

        })

    }


    return <>
        <strong>Stages PlanHours: {sumHours}h</strong>
        <Button onClick={gen}>Gen</Button>
        {/*<Stringify item={stagesInfo}></Stringify>*/}
        {(stages || []).map((it, ind) => {
            let key = it.key;
            stagesInfo[key] = stagesInfo[key] || {}
            let stInfo = stagesInfo[key]
            return (<div key={ind} className={+stInfo?.planHours ? '' : 'o5'} style={{marginTop: '10px'}}>
                <div>
                    {it?.name}
                </div>

                <div className="ib" style={{width: '60px'}}>
                    <Input
                        type={'number'}
                        placeholder={'PHours'} value={stInfo?.planHours} onChange={(v) => {
                        stInfo.planHours = v;
                        _onChange()
                    }}></Input>
                </div>
                {!isPeriod && <div className="ib" style={{width: '100px'}}>
                    <Input type={"number"} placeholder={'Days'} value={stInfo?.days} onChange={(v) => {
                        stInfo.days = v;
                        _onChange()
                    }}></Input>
                </div>}
                {!isPeriod && <div className="ib" style={{width: '100px'}}>
                    <Datepicker
                        value={stInfo.startForce} onChange={v => {
                        stInfo.startForce = v;
                        _onChange()
                    }}
                        label={'Start ' + (stInfo.startOdb || '')}/>
                </div>}
                <div className={'ib'}>
                    <GroupButton
                        value={stInfo.status}
                        items={STATUS_ITEMS}
                        onChange={(v) => {
                            stInfo.status = v;
                            _onChange()
                        }}
                    ></GroupButton>
                </div>
            </div>)
        })}

    </>
}


export default ZadachiEdit
